/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import React, {
  useRef, useState, useEffect, useContext,
} from 'react';
import {
  Button, Card, Form, Row,
} from 'react-bootstrap';
import {
  CardCvcElement, CardExpiryElement,
  CardNumberElement, Elements, ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { navigate } from 'gatsby';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import Error from '../../Shared/Utils/Error';
import StripeProcessor from
  '../../Shared/Gateways/Processors/StripeProcessor';
import * as styles from
  '../../../styles/admin/PaymentCardPlaceholder.module.scss';
import { createPaymentIntent, updateChargebeeSubscription } from '../Services/ChargebeeService';
import AdminContext from '../../../context/Admin/AdminContext';
import {
  getCurrentPlanItem, isBDMUser, postEvent, toaster,
} from '../../../services/utils';
import { SubscriptionDetails } from './SubscriptionUpdateView';
import { NavigationRoute } from '../Services/NavigationService';

interface Props {
  cardProcessor?: any,
  subscriptionDetails: SubscriptionDetails,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean | string>>,
  waitForUpdate: (resourceVersion: string, attempt?: number) => Promise<void>
}
interface processStripePaymentPayload {
  stripe: any,
  elements: any
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#333',
      fontWeight: '500',
      fontFamily: 'Lato',
      fontSize: '18px',
      '::placeholder': {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#cccccc',
      },
    },
    invalid: {
      color: '#eb1c26',
    },
  },
};

const getFieldsErrors = (key: string) => ({
  cardNumber: 'Card number is invalid',
  cardExpiry: 'Card expiry is invalid',
  cardCvc: 'CVV/CVC is invalid',
}[key] || '');
type ErrorPointers = {
  [index: string]: string
}

const getErrorPointerForField = (key: string) => ({
  cardNumber: 'card_number',
  cardExpiry: 'expiry_date',
  cardCvc: 'cvv',
}[key] || '');

const validateElementsData = (elementsData: any) => {
  const updatedErrorPointers: ErrorPointers = {};
  for (const key in elementsData) {
    if (elementsData.hasOwnProperty(key)) {
      if (elementsData[key].error || elementsData[key].empty) {
        updatedErrorPointers[getErrorPointerForField(key)] = elementsData[key].error
          || (elementsData[key].empty && getFieldsErrors(key));
      }
    }
  }
  if (Object.keys(updatedErrorPointers).length > 0) {
    throw new Error({
      type: Error.TYPES.VALIDATION_ERROR,
      message: 'Stripe elements validation failed',
      nextAction: Error,
      errorPointers: updatedErrorPointers,
    });
  }
};

const StripeCardPayment: React.FC<Props> = ({
  cardProcessor,
  subscriptionDetails,
  setIsLoading,
  waitForUpdate,
}) => {
  const cardNumberRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);

  const [stripeState, setStripeState] = useState({
    stripeElementsKey: 0,
    stripePromise: null,
  });
  const [elementsData, setElementsData] = useState({
    cardNumber: {
      empty: true,
    },
    cardExpiry: {
      empty: true,
    },
    cardCvc: {
      empty: true,
    },
  });
  const [cardholder, setCardholder] = useState('');
  const [billingAddress, setBillingAddress] = useState({
    stateCode: '',
    zip: '',
  });
  const [errorPointers, setErrorPointers] = useState<ErrorPointers>({});
  const [errorTimeoutId, setErrorTimeoutId] = useState<any>(null);
  const [loader, setLoader] = useState(false);

  const { companyInfo } = useContext(AdminContext);

  const quantityItem = subscriptionDetails.licenseCount;
  const itemPriceId = subscriptionDetails.priceId;

  const handleElementsChange = (e: any) => {
    setElementsData({
      ...elementsData,
      [e.elementType]: {
        error: e.error?.message,
        empty: e.empty,
      },
    });
  };

  const getPaymentMethodId = async (elements: any, stripe: any) => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardholder,
        address: {
          country: companyInfo.location,
          postal_code: billingAddress.zip,
        },
      },
    });
    if (error) {
      throw error;
    }
    return paymentMethod.id;
  };

  const initiateStripePayment = async (paymentMethodId: string) => {
    const payload = {
      item_price_id: itemPriceId,
      quantity: quantityItem + 1,
      customer: '',
      gateway_params: {
        gateway: 'stripe',
        payment_method_id: paymentMethodId,
        gateway_account_id: cardProcessor.id,
      },
      one_click_purchase: false,
    };
    const paymentIntent = await createPaymentIntent(payload, companyInfo?.id);

    return paymentIntent;
  };

  const updateChargebeeSubscriptionDetails = async (gwToken: string) => {
    const payload = {
      price_id: itemPriceId,
      quantity: quantityItem + 1,
      gw_token: gwToken,
      gateway_account_id: cardProcessor.id,
    };
    return updateChargebeeSubscription(payload, companyInfo?.id);
  };

  const processCardPayment = async (e: any, { elements, stripe }: processStripePaymentPayload) => {
    e.preventDefault();
    if (subscriptionDetails.licenseCount < subscriptionDetails.minimumLicensePurchaseCount) {
      toaster(`Must purchase a minimum license count of ${subscriptionDetails.minimumLicensePurchaseCount}`, 'error');
      return;
    }
    setLoader(true);
    try {
      validateElementsData(elementsData);
      const paymentMethodId = await getPaymentMethodId(elements, stripe);
      setIsLoading('Please wait while we are processing your purchase.');
      const stripeProcessor = new StripeProcessor(
        stripe, elements,
      );
      const paymentIntent = await initiateStripePayment(paymentMethodId);
      await stripeProcessor.authorisePaymentIntent(
        paymentIntent,
      );
      const {
        subscription: { resource_version: resourceVersion },
      } = await updateChargebeeSubscriptionDetails(paymentIntent.id);
      setIsLoading('Updating purchase info...');
      await waitForUpdate(resourceVersion);
      toaster('Payment succeeded');
      navigate(NavigationRoute.COMPANY_PAGE);
    } catch (error: any) {
      console.log(error);
      let errorMessage = error.message || 'Something went wrong';
      if (error instanceof Error) {
        errorMessage = error.getFirstErrorMessage();
        let processed = false;
        if (error.nextAction === Error.NEXT_ACTIONS.UNKNOWN && error.errorPointers) {
          processed = displayError(error.errorPointers);
        }
        if (processed) {
          error.handled = true;
        }
      } else if (error?.response?.data?.errors[0]?.title) {
        // TODO: transform at error source
        errorMessage = error?.response?.data?.errors[0]?.title;
      }
      toaster(errorMessage, 'error');
      setIsLoading(false);
      setLoader(false);
    }
  };

  const displayError = (updatedErrorPointers: any) => {
    let scrollToRef: any;
    const arrayOfErrorPointerKeys = Object.keys(updatedErrorPointers);
    const filteredCardErrorPointerKeys = arrayOfErrorPointerKeys.filter(
      (error) => ['card_number', 'expiry_date', 'cvv'].includes(error),
    );
    if (filteredCardErrorPointerKeys?.length) {
      scrollToRef = cardNumberRef;
    }
    if (scrollToRef) {
      setErrorPointers({
        ...updatedErrorPointers,
      });
      if (errorTimeoutId) {
        clearInterval(errorTimeoutId);
      }
      const tempTimeoutId = setTimeout(() => {
        setErrorPointers({});
      }, 5000);
      setErrorTimeoutId(tempTimeoutId);
      scrollToRef?.current?.scrollIntoView();
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (cardProcessor?.id) {
      const stripePromise: any = loadStripe(cardProcessor?.gatewayPublicKey,
        { locale: 'en' });
      setStripeState({
        stripePromise,
        stripeElementsKey: stripeState?.stripeElementsKey + 1,
      });
    }
  }, [cardProcessor]);

  return (
    <Elements
      key={stripeState.stripeElementsKey}
      stripe={stripeState.stripePromise}
    >
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <Form
            className="sh-payment-form"
            onSubmit={
              (e) => processCardPayment(e, { elements, stripe })
            }
          >
            <Form.Group
              className="form-group"
              controlId="cardNumber"
              ref={cardNumberRef}
            >
              <div className={styles.cardNumberSection}>
                <Form.Label>
                  Card number
                </Form.Label>
              </div>
              <CardNumberElement
                className="sh-input"
                onChange={handleElementsChange}
                options={CARD_ELEMENT_OPTIONS}
              />
              <div className={styles.cardFieldError}>
                <span>
                  {errorPointers.card_number}
                </span>
              </div>
            </Form.Group>
            <Row className={styles.expirySection}>
              <Form.Group
                className={styles.expirySectionGroup}
                controlId="cardExpiry"
                ref={cardExpiryRef}
              >
                <Form.Label className="card-expiry">
                  Expiry date
                </Form.Label>
                <CardExpiryElement
                  className="sh-input"
                  onChange={handleElementsChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
                <div className={styles.cardFieldError}>
                  <span className="card-error">
                    {errorPointers.expiry_date}
                  </span>
                </div>
              </Form.Group>
              <Form.Group
                className="cvcField"
                controlId="cardCvc"
                ref={cardCvcRef}
              >
                <Form.Label>
                  CVV/CVC
                </Form.Label>
                <CardCvcElement
                  className="sh-input"
                  onChange={handleElementsChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
                <div className={styles.cardFieldError}>
                  <span className="card-error">
                    {errorPointers.cvv}
                  </span>
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="form-group"
                controlId="cardName"
              >
                <Form.Label>
                  Name on card
                </Form.Label>
                <Form.Control
                  className="sh-name-input"
                  onChange={(e) => setCardholder(e.target.value)}
                  value={cardholder}
                  type="text"
                  name="name"
                  placeholder="Your name on the card"
                  autoComplete="off"
                  required
                />
              </Form.Group>
              <Form.Group
                className="form-group"
                controlId="cardZipCode"
              >
                <Form.Label>
                  Zip code
                </Form.Label>
                <Form.Control
                  className="sh-zip-input"
                  onChange={(e) => setBillingAddress(
                    { ...billingAddress, zip: e.target.value },
                  )}
                  value={billingAddress.zip}
                  type="text"
                  name="zipCode"
                  placeholder="000 - 000"
                  autoComplete="off"
                  required
                />
              </Form.Group>
            </Row>
            <Card className={styles.ctaSection}>
              <Button disabled={loader} type="submit" className={styles.payBtn}>
                <img
                  className={styles.shoppingBagIcon}
                  src="/images/admin/shopping-bag-Icon.png"
                  alt="Shopping bag"
                />
                Buy now
              </Button>
              {loader && <Spin className={styles.spinner} />}
            </Card>
          </Form>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default StripeCardPayment;
