import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import * as styles from '../../../styles/admin/ProductCard.module.scss';
import PlanSwitcher from './PlanSwitcher';
import { BillingFrequency, SubscriptionDetails } from './SubscriptionUpdateView';
import AdminContext from '../../../context/Admin/AdminContext';
import { estimateForSubscriptionUpdate } from '../Services/ChargebeeService';

type Props = {
  setSubscriptionDetails: React.Dispatch<React.SetStateAction<SubscriptionDetails>>,
  subscriptionDetails: SubscriptionDetails,
}
interface CurrencySymbolDetails {
  [key: string]: string;
}

const currencySymbolDetails: CurrencySymbolDetails = {
  usd: '$',
  eur: '€',
  gbp: '£',
  zar: 'R',
};

const ProductCard: React.FC<Props> = ({
  subscriptionDetails,
  setSubscriptionDetails,
}) => {
  const { companyInfo } = useContext(AdminContext);
  const [estimate, setEstimate] = useState<any>(null);

  const { unitPrice, minimumLicensePurchaseCount } = subscriptionDetails;
  const totalCost = unitPrice * subscriptionDetails.licenseCount;
  const billingFrequency = subscriptionDetails.billingFrequency === BillingFrequency.ANNUAL ? 'year' : 'month';
  const isLicenseQuantityInValid = subscriptionDetails.licenseCount < minimumLicensePurchaseCount;

  // Doesn't play well with random delay causing response order changes
  // TODO: cancel previous request before initiating new
  const refreshEstimate = (enterpriseId: string, itemPriceId: string, licenseCount: number) => {
    const payload = {
      item_price_id: itemPriceId,
      quantity: licenseCount + 1,
    };
    estimateForSubscriptionUpdate(
      payload, enterpriseId,
    ).then((updatedEstimate) => {
      console.log(updatedEstimate, itemPriceId, licenseCount);
      setEstimate(updatedEstimate);
    }).catch((error) => {
      console.log('Failed to estimate charges', error);
      setEstimate(null);
    });
  };

  const debouncedEstimation = useMemo(() => debounce(refreshEstimate, 500), []);

  useEffect(() => {
    if (companyInfo.id && subscriptionDetails.priceId) {
      debouncedEstimation(
        companyInfo.id, subscriptionDetails.priceId, subscriptionDetails.licenseCount,
      );
    }
  }, [companyInfo.id, subscriptionDetails.priceId, subscriptionDetails.licenseCount]);

  let nextBillingText = dayjs().add(1, billingFrequency).format('DD MMMM YYYY');
  let licenseCountBeingChargeFor = subscriptionDetails.licenseCount;
  let payableAmount = totalCost;
  // let creditsApplied = 0;
  // let creditsGenerated = 0;
  if (estimate) {
    nextBillingText = dayjs.unix(estimate.subscription_estimate.next_billing_at).format('DD MMMM YYYY');
    if (estimate.invoice_estimate) {
      licenseCountBeingChargeFor = estimate.invoice_estimate.line_items[0].quantity;
      payableAmount = estimate.invoice_estimate.amount_due / 100;
      // creditsApplied = estimate.invoice_estimate.credits_applied / 100;
    } else {
      licenseCountBeingChargeFor = 0;
      payableAmount = 0;
    }
    if (estimate.credit_note_estimates?.[0]) {
      // creditsGenerated = estimate.credit_note_estimates[0].total / 100;
    }
  }

  const handleInputChange = (e: any) => {
    setSubscriptionDetails((prevState) => ({
      ...prevState,
      licenseCount: Number(e.target.value),
    }));
  };

  const { currencyCode } = subscriptionDetails;
  const currencySymbol = currencySymbolDetails[currencyCode];

  return (
    <div className={styles.ProductContainer}>
      <div className={styles.innerSection}>
        <h2 className={styles.orderDetails}>Order details</h2>
        <div className={styles.detailsSection}>
          <div className={styles.detailsTitle}>
            <p>Billing Frequency</p>
            <p>Number of users*</p>
            <p>
              Price
              {' '}
              <span className={styles.perUserTxt}>
                {' '}
                per user per
                {' '}
                {billingFrequency}
                {' '}
              </span>
              <p className={styles.customBilling}>
                <a href="mailto:support@upskillist.pro?subject=Upgrade+Queries">Contact</a>
                {' '}
                for custom billing.
              </p>
            </p>
            <p className={styles.total}>Total</p>
          </div>
          <div className={styles.detailsValue}>
            <PlanSwitcher
              setSubscriptionDetails={setSubscriptionDetails}
              subscriptionDetails={subscriptionDetails}
            />
            <div className={styles.usersCountContainer}>
              <input
                className={`${styles.usersCount} ${isLicenseQuantityInValid ? styles.invalid : ''}`}
                onChange={handleInputChange}
                min={minimumLicensePurchaseCount}
                type="number"
                value={subscriptionDetails.licenseCount}
              />
            </div>
            <p className={styles.price}>
              {currencySymbol}
              {unitPrice}
              {' '}
              /-
            </p>
            <p className={styles.totalPrice}>
              {currencySymbol}
              {unitPrice}
              {' '}
              x
              {' '}
              {subscriptionDetails.licenseCount}
              {' '}
              =
              {' '}
              {currencySymbol}
              {totalCost}
              {' '}
              /-
            </p>
          </div>
        </div>
        <div className={styles.annualBilling}>
          <p>
            {subscriptionDetails.billingFrequency}
            {' '}
            Billing
          </p>
          <p>
            {currencySymbol}
            {totalCost}
            {' '}
            /-
          </p>
        </div>
        <p className={styles.disclaimer}>
          {licenseCountBeingChargeFor !== 0 && (
            <>
              You will be charged&nbsp;
              {currencySymbol}
              {payableAmount}
              &nbsp;today for&nbsp;
              {licenseCountBeingChargeFor}
              &nbsp;licenses.
              <br />
            </>
          )}
          {`Your next payment of ${currencySymbol}${totalCost} will be due ${nextBillingText}.`}
          <>
            <br />
            Note: Prorated charges & credits will be applied when applicable.
          </>
        </p>
      </div>
    </div>

  );
};

export default ProductCard;
