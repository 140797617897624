// extracted by mini-css-extract-plugin
export var ProductContainer = "ProductCard-module--ProductContainer--3lsOv";
export var spin = "ProductCard-module--spin--2jISP";
export var innerSection = "ProductCard-module--innerSection--2EBFe";
export var orderDetails = "ProductCard-module--orderDetails--3YoUz";
export var detailsSection = "ProductCard-module--detailsSection--3ArLg";
export var detailsTitle = "ProductCard-module--detailsTitle--2SYew";
export var perUserTxt = "ProductCard-module--perUserTxt--2_kFS";
export var customBilling = "ProductCard-module--customBilling--16VaF";
export var total = "ProductCard-module--total--3pVA5";
export var detailsValue = "ProductCard-module--detailsValue--3VTJH";
export var usersCountContainer = "ProductCard-module--usersCountContainer--3Rucm";
export var usersCount = "ProductCard-module--usersCount--37kYE";
export var invalid = "ProductCard-module--invalid--1Q28s";
export var price = "ProductCard-module--price--60iyh";
export var totalPrice = "ProductCard-module--totalPrice--22-d9";
export var annualBilling = "ProductCard-module--annualBilling--jqB_C";
export var disclaimer = "ProductCard-module--disclaimer--8yRph";