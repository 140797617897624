import { getCurrentPlanItem, hasInviteLimits, isBDMUser } from '../../../../services/utils';
import { fetchUserBySub } from '../../../DataStore/UserService';
import { getEnterpriseProfile, getUsersByEnterpriseID } from '../../../GraphQL/Services';
import { getCurrentUser } from '../../../Shared/Services/AuthService';
import { fetchAllInvitedUsers } from './InviteTabService';

const licenseCount = async (forceActiveUserCountForAccepted = false) => {
  const currentUser = await getCurrentUser();
  const subId = currentUser.attributes.sub;
  const activeUserId = currentUser.attributes?.['custom:active_user'];
  const userDetails = await fetchUserBySub(subId, activeUserId);
  const enterpriseId = userDetails?.enterpriseID;
  const licenseData = {};

  const {
    pendingInvitations, acceptedInvitations, totalInvitations,
  } = await fetchAllInvitedUsers(enterpriseId);

  const enterpriseProfile = await getEnterpriseProfile(enterpriseId);
  const invitesLimited = hasInviteLimits(enterpriseProfile?.subscription);

  const subscriptionInfo = getCurrentPlanItem(enterpriseProfile?.subscription);

  const totalEnterpriseLicense = subscriptionInfo?.quantity - subscriptionInfo?.freeQuantity;

  licenseData.invited = totalInvitations?.length || 0;
  licenseData.pending = pendingInvitations?.length || 0;
  licenseData.total = totalEnterpriseLicense || 0;

  // Enterprise with subscription plan
  if (invitesLimited || forceActiveUserCountForAccepted) {
    const allUsers = await getUsersByEnterpriseID(enterpriseId);
    const acceptedUsers = allUsers.filter((user) => {
      if (user?.deletedAt) return false;
      if (isBDMUser(user.email)) return false;
      return user?.enabled;
    });
    licenseData.accepted = acceptedUsers?.length || 0;
  } else {
    // Free trial
    licenseData.accepted = acceptedInvitations?.length || 0;
  }

  licenseData.available = licenseData.total - (licenseData.accepted + licenseData.pending) || 0;

  return licenseData;
};

export default licenseCount;
