import { API } from 'aws-amplify';
import { getHeaders } from '../../../services/utils';

interface Payload {
  name: string,
  phone: string,
  company: string,
}

interface ReactivatePayload {
  enterpriseId: string,
  subId: string,
}

const syncCustomerData = async (payload:Partial<Payload>, enterpriseId:string) : Promise<void> => {
  try {
    const apiName = 'enterpriseappchargebeewrapper';
    const path = '/sync/customer';
    const headers = await getHeaders();
    await API.post(apiName, path, {
      headers,
      body: {
        data: {
          type: 'customers',
          attributes: payload,
          relationships: {
            enterpriseprofile: {
              data: {
                type: 'enterpriseprofiles',
                id: enterpriseId,
              },
            },
          },
        },
      },
    });
  } catch (error:any) {
    console.log(error);
  }
};

const createPaymentIntent = async (payload: any, enterpriseId: string): Promise<any> => {
  const apiName = 'enterpriseappchargebeewrapper';
  const path = '/payment-intents';
  const headers = await getHeaders();
  const paymentIntent = await API.post(apiName, path, {
    headers,
    body: {
      data: {
        type: 'paymentintents',
        attributes: payload,
        relationships: {
          enterpriseprofile: {
            data: {
              type: 'enterpriseprofiles',
              id: enterpriseId,
            },
          },
        },
      },
    },
  });

  return paymentIntent;
};

const reactivateSubscription = async (payload:ReactivatePayload) : Promise<void> => {
  const apiName = 'enterpriseappchargebeewrapper';
  const path = `/subscriptions/${payload.enterpriseId}/reactivate`;
  const headers = await getHeaders();
  await API.post(apiName, path, {
    headers,
    body: {
      data: {
        id: payload.subId,
        type: 'subscriptions',
        attributes: { },
        relationships: {
          enterpriseprofile: {
            data: {
              type: 'enterpriseprofiles',
              id: payload.enterpriseId,
            },
          },
        },
      },
    },
  });
};

const updateChargebeeSubscription = async (payload: any, enterpriseId: string): Promise<any> => {
  const apiName = 'enterpriseappchargebeewrapper';
  const path = '/subscription';
  const headers = await getHeaders();
  const subscriptionResponse = await API.patch(apiName, path, {
    headers,
    body: {
      data: {
        type: 'subscription',
        attributes: payload,
        relationships: {
          enterpriseprofile: {
            data: {
              type: 'enterpriseprofiles',
              id: enterpriseId,
            },
          },
        },
      },
    },
  });

  return subscriptionResponse;
};

const getSavedCardDetails = async (enterpriseId: string): Promise<any> => {
  const apiName = 'enterpriseappchargebeewrapper';
  const path = `/payment-sources?filter[payment_source_type]=card&filter[enterprise_id]=${enterpriseId}`;
  const headers = await getHeaders();
  const savedCard = await API.get(apiName, path, {
    headers,
  });

  return savedCard;
};

const confirmPaymentIntent = async (payload: any, id: string): Promise<any> => {
  const apiName = 'enterpriseappchargebeewrapper';
  const path = `/payment-intents/${id}/confirm`;
  const headers = await getHeaders();
  const paymentIntent = await API.post(apiName, path, {
    headers,
    body: {
      data: {
        type: 'paymentintents',
        attributes: payload,
      },
    },
  });

  return paymentIntent;
};

const estimateForSubscriptionUpdate = async (payload: any, enterpriseId: string): Promise<any> => {
  const apiName = 'enterpriseappchargebeewrapper';
  const path = '/estimates/subscription-update';
  const headers = await getHeaders();
  const estimate = await API.post(apiName, path, {
    headers,
    body: {
      data: {
        type: 'estimates',
        attributes: payload,
        relationships: {
          enterpriseprofile: {
            data: {
              type: 'enterpriseprofiles',
              id: enterpriseId,
            },
          },
        },
      },
    },
  });

  return estimate;
};

export {
  syncCustomerData,
  createPaymentIntent,
  updateChargebeeSubscription,
  getSavedCardDetails,
  confirmPaymentIntent,
  reactivateSubscription,
  estimateForSubscriptionUpdate,
};
