import React from 'react';
import * as styles from '../../../styles/admin/PaymentSection.module.scss';

type Props = {
 useSavedCard: boolean;
 toggleCardHandler: () => void
 cardDetails: any
}

const SavedCard:React.FC<Props> = ({
  useSavedCard,
  toggleCardHandler,
  cardDetails,
}) => (
  <div className={styles.cardSelectionContainer}>
    <div className={styles.inputContainer}>
      <input
        type="radio"
        id="cardLabel"
        checked={useSavedCard}
        name="savedCard"
        value="HTML"
        onChange={toggleCardHandler}
      />
      <label htmlFor="cardLabel">
        <img
          className={styles.paymentLogo}
          src={`/images/admin/payment/${cardDetails?.card?.brand}.inline.svg`}
          alt="Card Logo"
        />
        {`${cardDetails?.card?.brand?.toUpperCase()} - ${cardDetails?.card?.last4}`}
      </label>
    </div>
    <p>
      {cardDetails?.card?.expiry_month}
      /
      {cardDetails?.card?.expiry_year}
    </p>
  </div>
);

export default SavedCard;
