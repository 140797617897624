// extracted by mini-css-extract-plugin
export var ccState = "PaymentCardPlaceholder-module--ccState--1yjph";
export var cardNumberSection = "PaymentCardPlaceholder-module--cardNumberSection--2QMq8";
export var paymentMethodLogos = "PaymentCardPlaceholder-module--paymentMethodLogos--3XKJq";
export var cardFieldError = "PaymentCardPlaceholder-module--cardFieldError--wzJJ8";
export var separator = "PaymentCardPlaceholder-module--separator--2IY8T";
export var ctaSection = "PaymentCardPlaceholder-module--ctaSection--2TtvW";
export var topSection = "PaymentCardPlaceholder-module--topSection--2V98Z";
export var price = "PaymentCardPlaceholder-module--price--1CIsH";
export var weeks = "PaymentCardPlaceholder-module--weeks--3-Mjp";
export var cancelText = "PaymentCardPlaceholder-module--cancelText--21hFS";
export var payBtn = "PaymentCardPlaceholder-module--payBtn--1d3jW";
export var shoppingBagIcon = "PaymentCardPlaceholder-module--shoppingBagIcon--2nFni";
export var spinner = "PaymentCardPlaceholder-module--spinner--2fziq";
export var hideCtaSection = "PaymentCardPlaceholder-module--hideCtaSection--1q5FL";
export var expirySection = "PaymentCardPlaceholder-module--expirySection--12l0N";
export var expirySectionGroup = "PaymentCardPlaceholder-module--expirySectionGroup--29gHB";