/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';
import AdminContext from '../../../context/Admin/AdminContext';
import * as styles from '../../../styles/admin/PaymentSection.module.scss';
import gatewayDetails from '../../Shared/Gateways/GatewayDetails';
import {
  confirmPaymentIntent,
  createPaymentIntent,
  updateChargebeeSubscription,
} from '../Services/ChargebeeService';
import SavedCard from './SavedCard';
import StripeCardPayment from './StripeCardPayment';
import stripePromise from './stripe';
import {
  getCurrentPlanItem, isBDMUser, postEvent, toaster,
} from '../../../services/utils';
import { SubscriptionDetails } from './SubscriptionUpdateView';
import StripeProcessor from '../../Shared/Gateways/Processors/StripeProcessor';
import Error from '../../Shared/Utils/Error';
import { getEnterpriseProfile } from '../../GraphQL/Services';
import { NavigationRoute } from '../Services/NavigationService';

type Props = {
  subscriptionDetails: SubscriptionDetails,
  cardDetails: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean | string>>
}

enum PaymentViews {
  SAVED_CARDS = 'saved_cards',
  ADD_CARD = 'add_card'
}

const cardProcessor = Object.values(gatewayDetails)[0];

const PaymentSection: React.FC<Props> = ({
  subscriptionDetails,
  cardDetails,
  setIsLoading,
}) => {
  const [paymentView, setPaymentView] = useState<PaymentViews>(PaymentViews.SAVED_CARDS);
  const [useSavedCard, setUseSavedCard] = useState(true);
  const [stripe, setStripe] = useState<any>(null);
  const [updateTimerId, setUpdateTimerId] = useState<any>(null);
  const { companyInfo, profileInfo } = useContext(AdminContext);

  const itemQuantity = subscriptionDetails.licenseCount;
  const itemPriceId = subscriptionDetails.priceId;

  const triggerIdentifyEvent = async (updatedCompanyProfile: any) => {
    const { subscription } = updatedCompanyProfile;
    const currentPlanItem = getCurrentPlanItem(subscription);
    const licenseCount = currentPlanItem.quantity - currentPlanItem.freeQuantity;
    const eventPayload = {
      userId: profileInfo?.id,
      enterpriseId: updatedCompanyProfile.id,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      traits: {
        email: profileInfo?.email,
        firstName: profileInfo?.firstName,
        lastName: profileInfo?.lastName,
        name: profileInfo?.name,
        roles: profileInfo?.roles,
        enterpriseSubStatus: subscription.status,
        enterprisePlan: currentPlanItem.itemPriceId,
        enterpriseLicenseCount: licenseCount,
        lastActive: dayjs().toISOString(),
        exclude: isBDMUser(profileInfo?.email),
      },
    };
    postEvent(eventPayload, '/identify');
  };

  const waitForUpdate = (resourceVersion: string, attempt = 0): Promise<void> => {
    if (updateTimerId) {
      clearTimeout(updateTimerId);
    }
    return new Promise((resolve) => {
      const tempTimeoutId = setTimeout(async () => {
        const updatedCompanyProfile = await getEnterpriseProfile(companyInfo?.id).catch(() => {
          toaster('Failed to refresh data. Kindly check after sometime', 'error');
          resolve();
        });
        if (updatedCompanyProfile?.subscription?.resourceVersion >= resourceVersion) {
          // success
          triggerIdentifyEvent(updatedCompanyProfile);
          resolve();
          return;
        }
        if (attempt > 3) {
          toaster('It is taking a bit longer than usual for the data to update. Kindly check after sometime', 'info');
          resolve();
        } else {
          await waitForUpdate(resourceVersion, attempt + 1);
          resolve();
        }
      }, 12000);
      setUpdateTimerId(tempTimeoutId);
    });
  };

  const toggleCardHandler = () => {
    setPaymentView(() => {
      if (paymentView === PaymentViews.ADD_CARD) {
        return PaymentViews.SAVED_CARDS;
      }
      return PaymentViews.ADD_CARD;
    });
    setUseSavedCard((prevState) => !prevState);
  };

  const updateChargebeeSubscriptionDetails = async (gwToken: string) => {
    const payload = {
      price_id: itemPriceId,
      quantity: itemQuantity + 1,
      gw_token: gwToken,
      gateway_account_id: cardDetails?.gateway_account_id,
    };
    return updateChargebeeSubscription(payload, companyInfo?.id);
  };

  const initiatePaymentIntent = async (paymentMethodId: string, customer: string) => {
    const payload = {
      item_price_id: itemPriceId,
      quantity: itemQuantity + 1,
      customer,
      gateway_params: {
        gateway: 'stripe',
        payment_method_id: paymentMethodId,
        gateway_account_id: cardDetails?.gateway_account_id,
      },
      one_click_purchase: true,
    };
    const paymentIntent = await createPaymentIntent(payload, companyInfo?.id);

    return paymentIntent;
  };

  const confirmStripePaymentIntent = async (paymentIntent: any) => {
    const payload = {
      amount: paymentIntent?.amount,
      gateway_params: {
        gateway: 'stripe',
        payment_method_id: paymentIntent?.paymentMethod,
        gateway_account_id: cardDetails?.gateway_account_id,
      },
    };
    const confirmedPaymentIntent = await confirmPaymentIntent(payload, paymentIntent.id);

    return confirmedPaymentIntent;
  };

  const savedCardPurchaseHandler = async (e: any) => {
    e.preventDefault();
    if (subscriptionDetails.licenseCount < subscriptionDetails.minimumLicensePurchaseCount) {
      toaster(`Must purchase a minimum license count of ${subscriptionDetails.minimumLicensePurchaseCount}`, 'error');
      return;
    }
    setIsLoading('Please wait while we are processing your purchase.');
    try {
      const referenceId = cardDetails?.reference_id;
      const slashIndex = referenceId.indexOf('/');
      const customer = referenceId.slice(0, slashIndex);
      const paymentMethodId = referenceId.slice(slashIndex + 1);
      const stripeProcessor = new StripeProcessor(
        stripe, null, // elements unused so far
      );
      const paymentIntent = await initiatePaymentIntent(paymentMethodId, customer);
      const authorisedPayment = await stripeProcessor.authorisePaymentIntent(paymentIntent, true);
      if (authorisedPayment.status === 'requires_confirmation') {
        await confirmStripePaymentIntent(authorisedPayment);
      }
      const {
        subscription: { resource_version: resourceVersion },
      } = await updateChargebeeSubscriptionDetails(paymentIntent?.id);
      setIsLoading('Updating purchase info...');
      await waitForUpdate(resourceVersion);
      toaster('Payment succeeded');
      navigate(NavigationRoute.COMPANY_PAGE);
    } catch (error: any) {
      let errorMessage = error.message || 'Something went wrong';
      if (error instanceof Error) {
        errorMessage = error.getFirstErrorMessage();
        error.handled = true;
      } else if (error?.response?.data?.errors[0]?.title) {
        // TODO: transform at error source
        errorMessage = error?.response?.data?.errors[0]?.title;
      }
      toaster(errorMessage, 'error');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!cardDetails) {
      setPaymentView(PaymentViews.ADD_CARD);
    }
  }, [cardDetails]);

  useEffect(() => {
    async function fetchStripe() {
      // TODO: fix usage of hard coded public key instead, use based on saved card gw account id
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
    }

    fetchStripe();
    return () => {
      if (updateTimerId) {
        clearTimeout(updateTimerId);
      }
    };
  }, []);

  return (
    <div className={styles.paymentContainer}>
      <h2 className={styles.paymentTitle}>Payment information</h2>
      <>
        <form>
          {
            cardDetails
            && (
              <>
                <h3>Select Payment Method</h3>
                <SavedCard
                  useSavedCard={useSavedCard}
                  toggleCardHandler={toggleCardHandler}
                  cardDetails={cardDetails}
                />
              </>
            )
          }
          {
            paymentView === PaymentViews.SAVED_CARDS
            && (
              <>
                <button
                  type="button"
                  className={styles.addCardCta}
                  onClick={toggleCardHandler}
                >
                  + Add new card
                </button>
                <button type="submit" onClick={savedCardPurchaseHandler} className={styles.payBtn}>
                  <img
                    className={styles.shoppingBagIcon}
                    src="/images/admin/shopping-bag-Icon.png"
                    alt="Shopping bag"
                  />
                  Buy now
                </button>
              </>
            )
          }
        </form>
      </>
      {
        paymentView === PaymentViews.ADD_CARD
        && (
          <StripeCardPayment
            cardProcessor={cardProcessor}
            subscriptionDetails={subscriptionDetails}
            setIsLoading={setIsLoading}
            waitForUpdate={waitForUpdate}
          />
        )
      }
    </div>
  );
};

export default PaymentSection;
