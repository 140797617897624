type CardProcessor ={
  id: string
  gateway:string
  platform: string
  integration: string
  platformConfig?: any
  gatewayLabel: string
  gatewayPublicKey?: string
  applicationId?: string
  locationId?: string
}
type GatewayDetails = {
  [index:string] : CardProcessor
}

const stripe1:CardProcessor = {
  id: process.env.StripeUKChargebeeAccountId || '',
  gateway: 'stripe',
  platform: 'chargebee',
  integration: 'gateway_sdk',
  gatewayLabel: '',
  gatewayPublicKey: process.env.StripeUKPublicKey,
};

const gatewayDetails:GatewayDetails = {
  [stripe1.id]: stripe1,
};

export default gatewayDetails;
