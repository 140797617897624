/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import * as styles from '../../../styles/admin/PlanSwitcher.module.scss';
import { BillingFrequency, SubscriptionDetails, SubscriptionPrices } from './SubscriptionUpdateView';
import prices from '../../Shared/Utils/subscription-prices.json';

type Props ={
  setSubscriptionDetails: React.Dispatch<React.SetStateAction<SubscriptionDetails>>,
  subscriptionDetails: SubscriptionDetails
}

const subscriptionPrices: SubscriptionPrices = prices;

const PlanSwitcher: React.FC<Props> = ({
  setSubscriptionDetails,
  subscriptionDetails,
}) => {
  const paymentFrequencyToggleHandler = () => {
    setSubscriptionDetails((prevState) => {
      const { currencyCode } = prevState;
      if (prevState.billingFrequency === BillingFrequency.MONTHLY) {
        const { unitPrice } = subscriptionPrices[currencyCode].annual;
        const priceId = `enterprise_annual_${currencyCode}`;
        return {
          ...prevState, billingFrequency: BillingFrequency.ANNUAL, unitPrice, priceId,
        };
      }

      const { unitPrice } = subscriptionPrices[prevState.currencyCode].monthly;
      const priceId = `enterprise_monthly_${currencyCode}`;
      return {
        ...prevState, billingFrequency: BillingFrequency.MONTHLY, unitPrice, priceId,
      };
    });
  };

  return (
    <div className={styles.planSwitcherSection}>
      <p className={`${styles.planType}
          ${subscriptionDetails.billingFrequency === BillingFrequency.ANNUAL ? styles.active : ''}`}
      >
        Annual
      </p>
      <div
        id="planSwitcher"
        className={`${styles.planToggler}
          ${subscriptionDetails.billingFrequency === BillingFrequency.ANNUAL ? styles.monthly : ''}`}
        onClick={paymentFrequencyToggleHandler}
      >
        <div className={styles.button} />
      </div>
      <p className={`${styles.planType}
          ${subscriptionDetails.billingFrequency === BillingFrequency.MONTHLY ? styles.active : ''}`}
      >
        Monthly
      </p>
    </div>
  );
};

export default PlanSwitcher;
