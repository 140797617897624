/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import Error from '../../Utils/Error';

/**
 *
 *
 * @class StripeProcessor
 */
class StripeProcessor {
  /**
   * Creates an instance of StripeProcessor.
   * @param {*} stripe
   * @param {*} elements
   * @memberof StripeProcessor
   */
  constructor(stripe, elements) {
    this.stripe = stripe;
    this.elements = elements;
  }

  /**
   *
   *
   * @param {*} paymentIntent
   * @param {boolean} actionConfirmation
   * @return {*}
   * @memberof StripeProcessor
   */
  async authorisePaymentIntent(paymentIntent, actionConfirmation = false) {
    if (['succeeded', 'requires_capture'].includes(paymentIntent.status)) {
      return paymentIntent;
    }
    let error; let authorisedPaymentIntent;
    try {
      if (paymentIntent.object === 'setup_intent') {
        if (actionConfirmation) {
          // saved card action
          ({ error, setupIntent: authorisedPaymentIntent } = await this.stripe.handleCardSetup(
            paymentIntent.client_secret, { payment_method_id: paymentIntent.payment_method_id },
          ));
        } else {
          ({ error, setupIntent: authorisedPaymentIntent } = await this.stripe.handleCardSetup(
            paymentIntent.client_secret,
          ));
        }
      } else if (actionConfirmation) {
        ({ error, paymentIntent: authorisedPaymentIntent } = await this.stripe.handleCardAction(
          paymentIntent.client_secret,
        ));
      } else {
        ({ error, paymentIntent: authorisedPaymentIntent } = await this.stripe.handleCardPayment(
          paymentIntent.client_secret,
        ));
      }
    } catch (err) {
      console.log('Stripe 3DS Error::', err);
      throw new Error({
        type: Error.TYPES.STRIPE_SDK_ERROR,
        message: 'Payment Intent authorisation failed',
        nextAction: Error.NEXT_ACTIONS.UNKNOWN,
        sourceError: err,
      });
    }
    if (error) {
      console.log('sca popup declined error:', error);
      throw new Error({
        type: Error.TYPES.STRIPE_SDK_ERROR,
        message: 'Payment Intent authorisation failed',
        nextAction: Error.NEXT_ACTIONS.UNKNOWN,
        errorPointers: {
          _all: error.message,
        },
        sourceError: error,
      });
    } else {
      return authorisedPaymentIntent;
    }
  }
}

export default StripeProcessor;
