import { Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminContext from '../../../context/Admin/AdminContext';
import {
  getCurrentPlanItem, isEligibleToManageBilling, isOnFreeTrialPlan, toaster,
} from '../../../services/utils';
import * as styles from '../../../styles/admin/SubscriptionUpdateView.module.scss';
import licenseCount from '../Accounts/Services/GetLicenseData';
import HeaderComponent from '../Header';
import { getSavedCardDetails } from '../Services/ChargebeeService';
import SidebarComponent from '../Sidebar';
import PaymentSection from './PaymentSection';
import ProductCard from './ProductCard';
import prices from '../../Shared/Utils/subscription-prices.json';
import { NavigationRoute } from '../Services/NavigationService';

export enum BillingFrequency {
  ANNUAL = 'annual',
  MONTHLY = 'monthly'
}

export interface SubscriptionPrices {
  [key: string]: any;
}

export type SubscriptionDetails = {
  unitPrice: number,
  licenseCount: number,
  billingFrequency: BillingFrequency,
  minimumLicensePurchaseCount: number,
  priceId: string
  currencyCode: string
}

export type LoadingState = {
  loadingState: boolean,
  loadingMessage: string
}

const subscriptionPrices: SubscriptionPrices = prices;

const defaultState = {
  licenseCount: 1,
  billingFrequency: BillingFrequency.ANNUAL,
  unitPrice: 120,
  minimumLicensePurchaseCount: 1,
  priceId: '',
  currencyCode: 'usd',
};

const SubscriptionUpdateView:React.FC = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails>(defaultState);
  const [cardDetails, setCardDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean | string>('');

  const { companyInfo, profileInfo } = useContext(AdminContext);

  const loadDependencies = async () => {
    try {
      const savedCard = await getSavedCardDetails(companyInfo.id);
      if (savedCard?.list?.length > 0) setCardDetails(savedCard.list[0].payment_source);
      await populateSubscriptionDetails();
    } catch (error: any) {
      console.log(error);
      toaster(
        error?.response?.data?.errors[0]?.title
        || 'Something went wrong!',
        'error',
      );
      toaster('Kindly refresh and retry.', 'error');
    }

    setIsLoading(false);
  };

  const populateSubscriptionDetails = async () => {
    const planDetails = getCurrentPlanItem(companyInfo?.subscription);
    const planId = planDetails.itemPriceId;
    const currencyCode = planId.split('_').pop() || 'usd';
    let billingFrequency = BillingFrequency.ANNUAL;
    if (!isOnFreeTrialPlan(companyInfo.subscription) && planId.includes('monthly')) {
      billingFrequency = BillingFrequency.MONTHLY;
    }
    const priceId = `enterprise_${billingFrequency}_${currencyCode}`;
    const { unitPrice } = subscriptionPrices[currencyCode][billingFrequency];
    const licenseDetails = await licenseCount(true);
    const minimumLicensePurchaseCount = licenseDetails.accepted + licenseDetails.pending;
    let licenseCountToUse = minimumLicensePurchaseCount;
    const currentLicenseCount = planDetails?.quantity - planDetails?.freeQuantity;
    if (minimumLicensePurchaseCount < currentLicenseCount) {
      licenseCountToUse = currentLicenseCount;
    }
    setSubscriptionDetails((prevState) => ({
      ...prevState,
      minimumLicensePurchaseCount,
      priceId,
      unitPrice,
      licenseCount: licenseCountToUse,
      currencyCode,
      billingFrequency,
    }));
  };

  useEffect(() => {
    if (companyInfo?.id && profileInfo?.id) {
      if (!isEligibleToManageBilling(companyInfo.subscription, profileInfo.roles)) {
        toaster('Not eligible for billing management', 'error');
        navigate(NavigationRoute.OVERVIEW_PAGE);
      }
      loadDependencies();
    }
  }, [profileInfo, companyInfo]);

  return (
    <div className={styles.paymentWrapper}>
      <HeaderComponent />
      <div className={styles.paymentContainer}>
        <SidebarComponent />
        <div className={styles.paymentSection}>
          <p className={styles.trialExpiry}>Billing Information</p>
          <h1 className={styles.updatedPaymentText}>
            Review or change your plan information here.
          </h1>
          <div className={styles.payment}>
            {isLoading !== false && (
              <Spin
                size="large"
                tip={`${isLoading}`}
                className={styles.loadingIndicator}
              />
            )}
            <ProductCard
              setSubscriptionDetails={setSubscriptionDetails}
              subscriptionDetails={subscriptionDetails}
            />
            { subscriptionDetails.priceId && (
              <PaymentSection
                subscriptionDetails={subscriptionDetails}
                cardDetails={cardDetails}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionUpdateView;
