import React from 'react';
import SubscriptionUpdateView from '../../components/Admin/Payment/SubscriptionUpdateView';
import Authenticate from '../../components/Identity/Authenticate';
import { AdminProvider } from '../../context/Admin/AdminContext';
import AuthorizationWrapper from '../../components/Admin/AuthorizationWrapper';

const Billing: React.FC = () => (
  <AdminProvider>
    <AuthorizationWrapper>
      <SubscriptionUpdateView />
    </AuthorizationWrapper>
  </AdminProvider>
);

export default Authenticate(Billing);
