/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import { API } from 'aws-amplify';
import { getHeaders } from '../../../../services/utils';

const fetchAllInvitedUsers = async (enterpriseId) => {
  const apiName = 'enterpriseappinviteuser';
  const path = `/invite/enterprise?enterprise_id=${enterpriseId}`;
  const headers = await getHeaders();
  const result = await API.get(apiName, path, {
    headers,
  });
  const items = result?.items?.length > 0 ? result?.items : [];
  const totalInvitations = items.filter((invitation) => (
    !(invitation?.status === 'REVOKED' && invitation?.inviteeID === null)
  ));
  const acceptedInvitations = totalInvitations.filter((invite) => (
    invite?.status === 'ACCEPTED' && invite?.inviteeID !== null
  ));
  const pendingInvitations = totalInvitations
    .filter((invite) => (
      invite?.status === 'REQUESTED' && invite?.inviteeID === null))
    .map((invitation) => {
      const dateFromQuery = new Date(invitation?.createdAt);
      const day = dateFromQuery.getDate();
      const month = dateFromQuery.getMonth() + 1;
      const year = dateFromQuery.getFullYear();
      const fullDate = `${month}/${day}/${year}`;
      const inviteeFormat = {
        email: invitation?.email,
        key: invitation?.id,
        status: invitation?.status,
        invitedOn: fullDate,
        version: invitation?._version,
        enterpriseId,
        inviterName: invitation?.inviter?.name,
      };
      return inviteeFormat;
    });
  return { pendingInvitations, acceptedInvitations, totalInvitations };
};

export {
  fetchAllInvitedUsers,
};
